const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/tmc-biz/%E7%81%AB%E8%BD%A6%E7%A5%A8-%E8%AE%A2%E5%8D%95%E6%93%8D%E4%BD%9C/reportExcelUsingPOST_1
const consumer_trains_order_report_excel = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/report/excel',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_order_report_excel;