import consumer_trains_order_report_list from '@/lib/data-service/haolv-default/consumer_trains_order_report_list'
import consumer_trains_order_report_excel from '@/lib/data-service/haolv-default/consumer_trains_order_report_excel' // 导出

export default {
    data () {
        return {
            params: {
                totalCount: 0,
                currentPage: 1,
                pageSize: 10,
                orderNo: '',
                buyerName: '',
                gmtPracticalPayment: '',
                paymentChannel: '',
                orderType: '',
                passengerNames: '',
                trainNo: '',
                startDate: '',
                arriveDate: '',
                fromStationName: '',
                toStationName: '',
            },
            loading: false,
            payTypeList: [
                {value: '', label: '所有'},
                {value: 'ALIPAY', label: '支付宝支付'},
                {value: 'WECHAT', label: '微信支付'},
                {value: 'YEEPAY', label: '易宝支付'},
                {value: 'CREDIT', label: '授信支付'},
                {value: 'UNIONPAY', label: '网银支付'},
                {value: 'B2B_CHINAPAY', label: '企业银联支付'},
                {value: 'B2C_CHINAPAY', label: '个人银联支付'},
                {value: 'PERSON_UNIONPAY', label: '个人网银支付'},
                {value: 'CREDIT_UNIONPAY', label: '个人信用卡网银支付'},
                {value: 'YPAY', label: '胤钱宝支付'},
            ],
            orderTypeList: [
                {value: '', label: '全部'},
                {value: 1, label: '预订'},
                {value: 2, label: '抢票'},
            ],
            list: [],
            fileUrl: '',
            canSubmit: true,
        }
    },
    components: {
        // Pagination,
        // DomesticTicketAirportSelector
    },
    methods: {
        search() {
            this.params.currentPage = 1;
            this.getList()
        },
        reset() {
            this.params = {
                totalCount: 0,
                currentPage: 1,
                pageSize: 10,
                orderNo: '',
                partnerOrderNo: '',
                companyName: '',
                buyerName: '',
                gmtPracticalPayment: '',
                startDate: '',
                arriveDate: '',
                fromStationName: '',
                toStationName: '',
            };
            this.search()
        },
        getList() {
            this.loading = true;
            consumer_trains_order_report_list(this.params).then(res => {
                this.loading = false;
                this.list = res.datas.pageData;
                this.params.totalCount = res.datas.totalCount
            }).catch(() => {
                this.loading = false;
                this.list = [];
                this.params.totalCount = 0
            })
        },
        handleSizeChange(size) {
            this.params.currentPage = 1;
            this.params.pageSize = size;
            this.getList()
        },
        handleCurrentChange(page) {
            this.params.currentPage = page;
            this.getList()
        },
        toPreview() {
            if (!this.canSubmit) {
                return
            }
            consumer_trains_order_report_excel(this.params).then(res => {
                this.canSubmit = true;
                if (res.datas) {
                    this.fileUrl = 'http://' + res.datas;
                    this.$nextTick(() => {
                        this.$refs.downloadBtn.click()
                    })
                } else {
                    this.$message.error('导出失败！');
                }
            }).catch(() => {
                this.canSubmit = true
            })
        },
    },
    created () {},
    mounted () {},
    activated () {
        this.reset()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},

}